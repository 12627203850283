.App {
  text-align: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.main {
  margin-top: 20%;
  color: #ffffff;
}

.coming-soon {
  color: rgb(124, 181, 255);
}
